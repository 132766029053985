/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /** Pompe à chaleur AIR EAU **/
    --ion-color-pac-air-eau: #D32D7C;
    --ion-color-pac-air-eau-rgb: 211,45,124;
    --ion-color-pac-air-eau-contrast: #ffffff;
    --ion-color-pac-air-eau-contrast-rgb: 255,255,255;
    --ion-color-pac-air-eau-shade: #ba286d;
    --ion-color-pac-air-eau-tint: #d74289;

    /** Pompe à chaleur AIR AIR **/
    --ion-color-pac-air-air: #469CDE;
    --ion-color-pac-air-air-rgb: 70,156,222;
    --ion-color-pac-air-air-contrast: #ffffff;
    --ion-color-pac-air-air-contrast-rgb: 0,0,0;
    --ion-color-pac-air-air-shade: #3e89c3;
    --ion-color-pac-air-air-tint: #59a6e1;

    /** Ballon thermodynamique **/
    --ion-color-ballon-thermodynamique: #EBBE40;
    --ion-color-ballon-thermodynamique-rgb: 235,190,64;
    --ion-color-ballon-thermodynamique-contrast: #ffffff;
    --ion-color-ballon-thermodynamique-contrast-rgb: 0,0,0;
    --ion-color-ballon-thermodynamique-shade: #cfa738;
    --ion-color-ballon-thermodynamique-tint: #edc553;

    /** Photovolatïque **/
    --ion-color-photovoltaique: #9EC043;
    --ion-color-photovoltaique-rgb: 158,192,67;
    --ion-color-photovoltaique-contrast: #ffffff;
    --ion-color-photovoltaique-contrast-rgb: 0,0,0;
    --ion-color-photovoltaique-shade: #8ba93b;
    --ion-color-photovoltaique-tint: #a8c656;

    /** Isolation **/
    --ion-color-isolation: #884D8F;
    --ion-color-isolation-rgb: 136,77,143;
    --ion-color-isolation-contrast: #ffffff;
    --ion-color-isolation-contrast-rgb: 255,255,255;
    --ion-color-isolation-shade: #78447e;
    --ion-color-isolation-tint: #945f9a;

    /** Autre matériel **/
    --ion-color-autre-materiel: #E59735;
    --ion-color-autre-materiel-rgb: 229,151,53;
    --ion-color-autre-materiel-contrast: #ffffff;
    --ion-color-autre-materiel-contrast-rgb: 0,0,0;
    --ion-color-autre-materiel-shade: #ca852f;
    --ion-color-autre-materiel-tint: #e8a149;

    /** Installation **/
    --ion-color-installation: #66312D;
    --ion-color-installation-rgb: 102,49,45;
    --ion-color-installation-contrast: #ffffff;
    --ion-color-installation-contrast-rgb: 255,255,255;
    --ion-color-installation-shade: #5a2b28;
    --ion-color-installation-tint: #754642;

    /** Mode de paiement **/
    --ion-color-mode-paiement: #34785C;
    --ion-color-mode-paiement-rgb: 52,120,92;
    --ion-color-mode-paiement-contrast: #ffffff;
    --ion-color-mode-paiement-contrast-rgb: 255,255,255;
    --ion-color-mode-paiement-shade: #2e6a51;
    --ion-color-mode-paiement-tint: #48866c;

    /** Emargement **/
    --ion-color-emargement: #BCBCBC;
    --ion-color-emargement-rgb: 188,188,188;
    --ion-color-emargement-contrast: #000000;
    --ion-color-emargement-contrast-rgb: 0,0,0;
    --ion-color-emargement-shade: #a5a5a5;
    --ion-color-emargement-tint: #c3c3c3;
}

@media (prefers-color-scheme: dark) {
    /*
     * Dark Colors
     * -------------------------------------------
     */

    body {
        --ion-color-primary: #428cff;
        --ion-color-primary-rgb: 66,140,255;
        --ion-color-primary-contrast: #ffffff;
        --ion-color-primary-contrast-rgb: 255,255,255;
        --ion-color-primary-shade: #3a7be0;
        --ion-color-primary-tint: #5598ff;

        --ion-color-secondary: #50c8ff;
        --ion-color-secondary-rgb: 80,200,255;
        --ion-color-secondary-contrast: #ffffff;
        --ion-color-secondary-contrast-rgb: 255,255,255;
        --ion-color-secondary-shade: #46b0e0;
        --ion-color-secondary-tint: #62ceff;

        --ion-color-tertiary: #6a64ff;
        --ion-color-tertiary-rgb: 106,100,255;
        --ion-color-tertiary-contrast: #ffffff;
        --ion-color-tertiary-contrast-rgb: 255,255,255;
        --ion-color-tertiary-shade: #5d58e0;
        --ion-color-tertiary-tint: #7974ff;

        --ion-color-success: #2fdf75;
        --ion-color-success-rgb: 47,223,117;
        --ion-color-success-contrast: #000000;
        --ion-color-success-contrast-rgb: 0,0,0;
        --ion-color-success-shade: #29c467;
        --ion-color-success-tint: #44e283;

        --ion-color-warning: #ffd534;
        --ion-color-warning-rgb: 255,213,52;
        --ion-color-warning-contrast: #000000;
        --ion-color-warning-contrast-rgb: 0,0,0;
        --ion-color-warning-shade: #e0bb2e;
        --ion-color-warning-tint: #ffd948;

        --ion-color-danger: #ff4961;
        --ion-color-danger-rgb: 255,73,97;
        --ion-color-danger-contrast: #ffffff;
        --ion-color-danger-contrast-rgb: 255,255,255;
        --ion-color-danger-shade: #e04055;
        --ion-color-danger-tint: #ff5b71;

        --ion-color-dark: #f4f5f8;
        --ion-color-dark-rgb: 244,245,248;
        --ion-color-dark-contrast: #000000;
        --ion-color-dark-contrast-rgb: 0,0,0;
        --ion-color-dark-shade: #d7d8da;
        --ion-color-dark-tint: #f5f6f9;

        --ion-color-medium: #989aa2;
        --ion-color-medium-rgb: 152,154,162;
        --ion-color-medium-contrast: #000000;
        --ion-color-medium-contrast-rgb: 0,0,0;
        --ion-color-medium-shade: #86888f;
        --ion-color-medium-tint: #a2a4ab;

        --ion-color-light: #222428;
        --ion-color-light-rgb: 34,36,40;
        --ion-color-light-contrast: #ffffff;
        --ion-color-light-contrast-rgb: 255,255,255;
        --ion-color-light-shade: #1e2023;
        --ion-color-light-tint: #383a3e;
    }

    /*
     * iOS Dark Theme
     * -------------------------------------------
     */

    .ios body {
        --ion-background-color: #000000;
        --ion-background-color-rgb: 0,0,0;

        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255,255,255;

        --ion-color-step-50: #0d0d0d;
        --ion-color-step-100: #1a1a1a;
        --ion-color-step-150: #262626;
        --ion-color-step-200: #333333;
        --ion-color-step-250: #404040;
        --ion-color-step-300: #4d4d4d;
        --ion-color-step-350: #595959;
        --ion-color-step-400: #666666;
        --ion-color-step-450: #737373;
        --ion-color-step-500: #808080;
        --ion-color-step-550: #8c8c8c;
        --ion-color-step-600: #999999;
        --ion-color-step-650: #a6a6a6;
        --ion-color-step-700: #b3b3b3;
        --ion-color-step-750: #bfbfbf;
        --ion-color-step-800: #cccccc;
        --ion-color-step-850: #d9d9d9;
        --ion-color-step-900: #e6e6e6;
        --ion-color-step-950: #f2f2f2;

        --ion-item-background: #000000;

        --ion-card-background: #1c1c1d;
    }

    .ios ion-modal {
        --ion-background-color: var(--ion-color-step-100);
        --ion-toolbar-background: var(--ion-color-step-150);
        --ion-toolbar-border-color: var(--ion-color-step-250);
    }


    /*
     * Material Design Dark Theme
     * -------------------------------------------
     */

    .md body {
        --ion-background-color: #121212;
        --ion-background-color-rgb: 18,18,18;

        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255,255,255;

        --ion-border-color: #222222;

        --ion-color-step-50: #1e1e1e;
        --ion-color-step-100: #2a2a2a;
        --ion-color-step-150: #363636;
        --ion-color-step-200: #414141;
        --ion-color-step-250: #4d4d4d;
        --ion-color-step-300: #595959;
        --ion-color-step-350: #656565;
        --ion-color-step-400: #717171;
        --ion-color-step-450: #7d7d7d;
        --ion-color-step-500: #898989;
        --ion-color-step-550: #949494;
        --ion-color-step-600: #a0a0a0;
        --ion-color-step-650: #acacac;
        --ion-color-step-700: #b8b8b8;
        --ion-color-step-750: #c4c4c4;
        --ion-color-step-800: #d0d0d0;
        --ion-color-step-850: #dbdbdb;
        --ion-color-step-900: #e7e7e7;
        --ion-color-step-950: #f3f3f3;

        --ion-item-background: #1e1e1e;

        --ion-toolbar-background: #1f1f1f;

        --ion-tab-bar-background: #1f1f1f;

        --ion-card-background: #1e1e1e;
    }
}

.ion-color-pac-air-eau {
    --ion-color-base: var(--ion-color-pac-air-eau);
    --ion-color-base-rgb: var(--ion-color-pac-air-eau-rgb);
    --ion-color-contrast: var(--ion-color-pac-air-eau-contrast);
    --ion-color-contrast-rgb: var(--ion-color-pac-air-eau-contrast-rgb);
    --ion-color-shade: var(--ion-color-pac-air-eau-shade);
    --ion-color-tint: var(--ion-color-pac-air-eau-tint);
}

.ion-color-pac-air-air {
    --ion-color-base: var(--ion-color-pac-air-air);
    --ion-color-base-rgb: var(--ion-color-pac-air-air-rgb);
    --ion-color-contrast: var(--ion-color-pac-air-air-contrast);
    --ion-color-contrast-rgb: var(--ion-color-pac-air-air-contrast-rgb);
    --ion-color-shade: var(--ion-color-pac-air-air-shade);
    --ion-color-tint: var(--ion-color-pac-air-air-tint);
}

.ion-color-ballon-thermodynamique {
    --ion-color-base: var(--ion-color-ballon-thermodynamique);
    --ion-color-base-rgb: var(--ion-color-ballon-thermodynamique-rgb);
    --ion-color-contrast: var(--ion-color-ballon-thermodynamique-contrast);
    --ion-color-contrast-rgb: var(--ion-color-ballon-thermodynamique-contrast-rgb);
    --ion-color-shade: var(--ion-color-ballon-thermodynamique-shade);
    --ion-color-tint: var(--ion-color-ballon-thermodynamique-tint);
}

.ion-color-photovoltaique {
    --ion-color-base: var(--ion-color-photovoltaique);
    --ion-color-base-rgb: var(--ion-color-photovoltaique-rgb);
    --ion-color-contrast: var(--ion-color-photovoltaique-contrast);
    --ion-color-contrast-rgb: var(--ion-color-photovoltaique-contrast-rgb);
    --ion-color-shade: var(--ion-color-photovoltaique-shade);
    --ion-color-tint: var(--ion-color-photovoltaique-tint);
}

.ion-color-isolation {
    --ion-color-base: var(--ion-color-isolation);
    --ion-color-base-rgb: var(--ion-color-isolation-rgb);
    --ion-color-contrast: var(--ion-color-isolation-contrast);
    --ion-color-contrast-rgb: var(--ion-color-isolation-contrast-rgb);
    --ion-color-shade: var(--ion-color-isolation-shade);
    --ion-color-tint: var(--ion-color-isolation-tint);
}

.ion-color-autre-materiel {
    --ion-color-base: var(--ion-color-autre-materiel);
    --ion-color-base-rgb: var(--ion-color-autre-materiel-rgb);
    --ion-color-contrast: var(--ion-color-autre-materiel-contrast);
    --ion-color-contrast-rgb: var(--ion-color-autre-materiel-contrast-rgb);
    --ion-color-shade: var(--ion-color-autre-materiel-shade);
    --ion-color-tint: var(--ion-color-autre-materiel-tint);
}

.ion-color-installation {
    --ion-color-base: var(--ion-color-installation);
    --ion-color-base-rgb: var(--ion-color-installation-rgb);
    --ion-color-contrast: var(--ion-color-installation-contrast);
    --ion-color-contrast-rgb: var(--ion-color-installation-contrast-rgb);
    --ion-color-shade: var(--ion-color-installation-shade);
    --ion-color-tint: var(--ion-color-installation-tint);
}

.ion-color-mode-paiement {
    --ion-color-base: var(--ion-color-mode-paiement);
    --ion-color-base-rgb: var(--ion-color-mode-paiement-rgb);
    --ion-color-contrast: var(--ion-color-mode-paiement-contrast);
    --ion-color-contrast-rgb: var(--ion-color-mode-paiement-contrast-rgb);
    --ion-color-shade: var(--ion-color-mode-paiement-shade);
    --ion-color-tint: var(--ion-color-mode-paiement-tint);
}

.ion-color-emargement {
    --ion-color-base: var(--ion-color-emargement);
    --ion-color-base-rgb: var(--ion-color-emargement-rgb);
    --ion-color-contrast: var(--ion-color-emargement-contrast);
    --ion-color-contrast-rgb: var(--ion-color-emargement-contrast-rgb);
    --ion-color-shade: var(--ion-color-emargement-shade);
    --ion-color-tint: var(--ion-color-emargement-tint);
}