ion-content {
    --background: #f4f4f4;
}

ion-card {
    border: 1px solid #E0E0E0;
}

ion-grid {
    --ion-grid-column-padding: 16px;
    border-collapse: collapse;
    border-style: hidden;
    padding: 0;
}

ion-row.header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.header ion-col:first-child {
    border-top-left-radius: 8px;
}

.header ion-col:last-child {
    border-top-right-radius: 8px;
}

#contractList {
    padding: 5px;
    background: #f4f4f4;
}

#contractList ion-item {
    border-radius: 5px;
}

#contractList ion-item:not(last-child) {
    margin-bottom: 5px;
}